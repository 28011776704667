
import onepegrustl from '../images/onepegrustl.svg'
import onepelogovar1 from '../images/onepevar1.svg'
import onepelogovar2 from '../images/onepelogovar2.svg'
import colourpallet from '../images/palletonepe.svg'
import onepephone from '../images/onepephone.svg'
import appui from '../images/appui.svg'
import iconpack1 from '../images/iconpack1.svg'
import iconpack2 from '../images/iconpack2.svg'
import iconpack3 from '../images/iconpack3.svg'
import interfacee from '../images/interface.svg'
import newui from '../images/newui.svg'
import BILL1 from '../images/BILL1.svg'
import BILL2 from '../images/BILL2.svg'
import onepoints from '../images/onepoints.svg'


function Onepe() {


    document.addEventListener('contextmenu', event => event.preventDefault());
    return(
        <div>
            {/* Main Page*/}
            <div className='h-screen'>
                <div className="flex flex-row w-full h-[15%]  pt-10  gap-5">
                    <div className='w-10'>

                    </div>
                    <div className=" bg-black w-2 ">
                    </div>

                    <div className="text-3xl text-balck ah w-[70%] h-auto flex  flex-col ">
                        <p className='w-full text-left'>B R A N D </p>
                        <p>I D E N T I T Y</p>
                    </div>
                    
                    <div className=' flex items-center justify-center'>
                        <img src={onepegrustl} className="w-[340px] h-[117px]" alt="guna"></img>
                    </div>
                    <div className='w-10'>

                    </div>

                </div>

                <div className='h-[45%] pt-10 flex items-center justify-center gap-10 '>
                    
                    <div className='flex flex-col items-center justify-center gap-5 pl-0'>
                        <p className='text-xl ab text-black '>L O G O / V A R 1</p>
                        <img src={onepelogovar1} className="" alt="guna"></img>
                    </div>
                    <div className='bg-[#BEBEBE] h-[25%] w-[1px]'>

                    </div>
                    <div className='flex flex-col items-center justify-center gap-5'>
                        <p className='text-xl ab text-black'>L O G O / V A R 2</p>
                        <img src={onepelogovar2} className="" alt="guna"></img>
                    </div>
                    <div className='bg-[#BEBEBE] h-[25%] w-[1px]'>

                    </div>
                    <div className='flex flex-col items-center justify-center gap-5 pr-0'>
                        <p className='text-xl ab text-black tracking-widest'>C O L O U R &nbsp; P A L L E T</p>
                        <img src={colourpallet} className="" alt="guna"></img>
                    </div>
                    

                </div>
                <div className='h-[2%] flex flex-row'>
                    <div className='h-[1px] w-[40%] bg-white'></div>
                    <div className='h-[1px] w-[15%] bg-[#BEBEBE]'></div>
                    <div className='h-[1px] w-[15%]  bg-white'></div>
                    <div className='h-[1px] w-[15%] bg-[#BEBEBE]'></div>


                </div>

                <div className='h-[38%] w-full flex flex-row  pl-5 pr-5 items-center justify-center gap-5'>
                    <div className='flex w-[65%] flex-col items-center justify-center'>
                        <div className='text-4xl first-letter: text-[#464646]'>A P P L I C A T I O N &nbsp; B O O T</div>
                        <div className='text-4xl ab bg-gradient-to-l from-[#2449A2] to-[#80BD6F] inline-block  text-transparent bg-clip-text w-full text-center'>M O T I O N &nbsp; G R A P H I C S</div>

                    </div>

                    <div className='bg-[#BEBEBE] h-[25%] w-[1px] '></div>


                    <div className='w-[35%] h-full flex flex-col items-center justify-center  '>
                        <p className='text-xl ab text-'>F O N T &nbsp; F A C E</p>
                        <div className='text-[53px] ab bg-gradient-to-r from-[#2449A2] to-[#80BD6F] inline-block  text-transparent bg-clip-text full'>Monteserat</div>
                        <p className='text-[#000000] opacity-50'>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et.Lorem ipsum dolor sit amet lorem ipsum dolor sit amet consectetur adipiscing elit </p>
                    </div>

                </div>

            </div>
            
            {/* Case Study */}
            <div className=''>
                <div className="flex flex-row w-full h-[15%]  pt-10  gap-5">
                    <div className='w-10'>

                    </div>

                    <div className=" bg-black w-2 "></div>

                    <div className="text-3xl text-balck ah w-[70%] h-auto flex  flex-col ">
                        <p className='w-full text-left'>C A S E </p>
                        <p>S T U D Y</p>
                    </div>
                </div>

                

                <div className='h-[85%] flex flex-row'>
                    <div className='w-[5%]'></div>

                    <div className='w-[65%] h-[50%] flex flex-col pt-20'>
                        <div className='text-[26px] text-black ab'>
                            P R O D U C T &nbsp; : &nbsp; O V E R V I E W
                        </div>
                        <div className='text-[19px] text-black al w-[80%] pt-5'>
                            OnePe is a financial technology (FinTech) app that is devoted to providing digital 
                            solutions for reducing the use of paper bills and streamlining the billing and payment process. 
                            Its goal is to make the process more efficient and environmentally friendly.
                        </div>

                        <div className='text-[26px] text-black ab pt-10'>
                            A I M
                        </div>
                        <div className='text-[19px] text-black al w-[80%] pt-5'>
                            Our objective for this fin-tech startup was to implement transformative changes that would 
                            enable it to compete with established players in the market and gain a head start in the race. 
                            To achieve this, we planned to redesign the user interface from scratch to give the app a personalised 
                            look. For the branding aspect, we wanted to convey a sleek, minimalistic aesthetic while also highlighting 
                            the company's focus on technology and environmental sustainability, which is why we chose blue and green 
                            accents. Our goal was to create a strong, distinctive brand identity for the startup.
                        </div>

                        <div className='text-[26px] text-black ab pt-10'>
                            S T R A TE G I E S &nbsp; : &nbsp; B U I L D
                        </div>
                        <div className='text-[19px] text-black al w-[80%] pt-5'>
                            <p className='ah text-[19px]'>Brand Revamp: A Complete Overhaul:<br></br> </p>
                            <div className='al text-[19px]'>
                                &nbsp;&nbsp;&nbsp;&nbsp;•Selecting the ideal theme.<br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;•Analysing the perfect font theme.<br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;•Building out of the box banners for play store and App store.<br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;•Motion graphic elements that results in attention to details. <br></br>
                            </div>

                            <p className='ah pt-5'>Innovative Design: Crafting the Ultimate User Interface: <br></br> </p>
                            <div className='al'>
                                &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;Design an interface that makes the app give the best visual as well as user experience. <br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;Scrap existing icons and craft them from scratch to compliment the new interface. <br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;Introduce new concepts like OnePoints wallet in the place of generic rewards rewards and bill history <br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;envelope for a better detail elevation.<br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;Integrate creative API's like third party maps for a unique stand.  <br></br>
                            </div>

                            <p className='ah pt-5'> Supportive Solutions: Your Guide to Expert Help Documentation : <br></br> </p>
                            <div className='al'>
                                &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;Make High quality explainer videos to make it easier for pitching the product to vendors <br></br>
                            </div>
                        </div>
                    </div>

                    <div className='w-[25%] bg-green- flex items-center justify-center'>
                        <img src={onepephone} className="" alt="guna"></img>  
                    </div>

                    <div className='w-[5%]'></div>
                </div>
            </div>

            {/* Application Launch Packages */}

            <div className='h-screen'>
                <div className="flex flex-row w-full h-[15%]  pt-10  gap-5">
                    <div className='w-10'>

                    </div>

                    <div className=" bg-black w-2 "></div>

                    <div className="text-3xl text-balck ah w-[70%] h-auto flex  flex-col ">
                        <p className='w-full text-left'>A P P L I C A T I O N  </p>
                        <p>L A U N C H &nbsp; P A C K A G E S</p>
                    </div>
                </div>

                <div className='flex flex-row'>
                    <div className='w-[5%]'>

                    </div>
                    <div className='flex w-[45%] items-center justify-center '>
                        <img src={appui} className="z-10" alt="guna"></img> 
                        <div className='flex flex-col -translate-x-16'>
                            <div className=' text-3xl ah bg-gradient-to-l from-[#2449A2] to-[#80BD6F] inline-block  text-transparent bg-clip-text w-[460px]'>A P P  &nbsp; S T O R E &nbsp; L A U N C H</div>
                            <div className='text-3xl ab bg-gradient-to-l from-[#2449A2] to-[#80BD6F] inline-block  text-transparent bg-clip-text w-[420px]'>C A R S O U S E L</div>
                        </div>
                        <div className='w-[1px] h-[15%]  bg-[#BEBEBE]'>
                            &nbsp;
                        </div>
                    </div>

                    <div className='flex flex-col pl-10 items-center justify-center'>
                        <div className='w-full '>
                            <img src={iconpack1} className="" alt="guna"></img>
                        </div>
                        <div className='w-full flex items-center justify-end pt-10'>
                            <div className='flex flex-col w-[80%] items-center justify-center'>
                                <div className=' text-3xl ah bg-gradient-to-l from-[#2449A2] to-[#80BD6F] inline-block  text-transparent bg-clip-text '>R E D E S I G N E D </div>
                                <div className='text-3xl ab bg-gradient-to-l from-[#2449A2] to-[#80BD6F] inline-block  text-transparent bg-clip-text '>I N &nbsp; - &nbsp; A P P &nbsp; I C O N S</div>
                            </div>
                            <img src={iconpack3} className="" alt="guna"></img>
                        </div>
                        <div className='w-full pt-10'>
                            <img src={iconpack2} className="" alt="guna"></img>
                        </div>

                    </div>
                </div>
            </div>

            {/* Systematic Analysis */}
            <div className='h-screen pt-20'>
                <div className="flex flex-row w-full h-[15%]  pt-10  gap-5">
                    <div className='w-10'>

                    </div>

                    <div className=" bg-black w-2 "></div>

                    <div className="text-3xl text-balck ah w-[70%] h-auto flex  flex-col ">
                        <p className='w-full text-left'>S Y S T E M A T I C &nbsp; A N A L Y S I S :  </p>
                        <p>P R E &nbsp; L O O K &nbsp; (I N T E R F A C E)</p>
                    </div>
                </div>

                <div className='flex flex-row'>
                    <div className='w-[50%] flex items-center justify-center pt-10'>
                        <img src={interfacee} className="" alt="guna"></img>
                    </div>

                    <div className='w-[1%] flex items-center justify-center '>
                        <div className='w-[1px] bg-[#BEBEBE] h-[60%]'>

                        </div>

                    </div>

                    <div className='w-[49%] flex flex-col gap-10 items-center justify-center'>
                        <div className='text-2xl text-black ah'>
                             P R E V I O S L Y &nbsp; E X I S T E D &nbsp; I N T E R F A C E
                        </div>
                        <div className='text-[19px] text-black al w-[80%] text-justify'>
                            Upon reviewing Onepe's interface, we noticed that it lacks the standout 
                            features and design elements that can set it apart from it's competitors. 
                            While the interface is functional, it lacks the visually appealing elements 
                            and advanced features that enhance the user experience. We also noticed a few 
                            flaws that negatively impact the user experience, such as the integration of  
                            Generic Maps on the explore screen and the use of a generic bill template on the 
                            bill screen. All these factors put together immediately made the app a little too 
                            basic. That's when we decided to act.
                        </div>

                    </div>

                </div>

            </div>


            {/* New App UI */}
            <div className=' pt-20 pb-20'>
                <div className="flex flex-row w-full h-[15%]  pt-10  gap-5">
                    <div className='w-10'>

                    </div>

                    <div className=" bg-black w-2 "></div>

                    <div className="text-3xl text-balck ah w-[70%] h-auto flex  flex-col ">
                        <p className='w-full text-left'>R E V A M P E D   </p>
                        <p>A P P &nbsp; U S E R &nbsp; I N T E R F A C E</p>
                    </div>
                </div>

                <div className='flex flex-col items-center justify-center h-auto'>
                    <div className='w-[10%]'>

                    </div>
                    <div className='w-[80%] flex items-center justify-center'>
                        <img src={newui} className="" alt="guna"></img>
                    </div>
                    <div className='w-[10%]'>

                    </div>
                
                </div>

            </div>

             {/* Additional Functions */}
             <div className='h-screen pt-20 pb-20'>
                <div className="flex flex-row w-full h-[15%]  pt-10  gap-5">
                    <div className='w-10'>

                    </div>

                    <div className=" bg-black w-2 "></div>

                    <div className="text-3xl text-balck ah w-[70%] h-auto flex  flex-col ">
                        <p className='w-full text-left'>A D D I T I O N A L &nbsp;  </p>
                        <p>F U N C T I O N S&nbsp; A D D E D</p>
                    </div>
                </div>

                <div className='flex flex-col h-[50%] items-center justify-center '>
                
                </div>

                <div className='flex flex-row gap-10 items-center justify-center h-[50%] bg-gradient-to-tr from-[#1C282C] to-[#5BA1B7] rounded-t-[100px]'>
                    <div className='w-[10%]'>

                    </div>

                    <div className='w-[20%] text-white text-[22px] items-center h-[50%]  justify-center'>
                        <p className='text-center'>D Y N A M I C <br></br>
                        B I L L &nbsp; L A Y O U T</p>
                        
                    </div>
                    <div className='w-[20%] -translate-y-60'>
                        <img src={BILL1} className="" alt="guna"></img>
                    </div>

                    <div className='w-[20%] text-white text-[22px] items-center h-[50%] justify-center'>
                        <img src={onepoints} className="" alt="guna"></img>  
                    </div>
                    <div className='w-[20%] -translate-y-60'>
                        <img src={BILL2} className="" alt="guna"></img>
                    </div>

                    <div className='w-[10%]'>

                    </div>

                </div>

            </div>
        </div>

       
       
    )
}

export default Onepe