import Navbar from './components/Navbar';
import './App.css';
import Home from './pages/index';
import ServicesC from './components/ServicesC';
import Onepe from './components/OnePe';
import Jeevan from './components/Jeevan'
import Monque from './components/Monque';
import Wayenedin from './components/Wayenedin';
import MancaveC from './components/MancaveC';
import CareerC from './components/CareerC';
import ConnectC from './components/ConnectC';
import Guna from './components/Zguna';
import Rishi from './components/Zrishi';
import Kiran from './components/Zkiran';
import Pratap from './components/Zpratap';
import Thiksha from './components/Zthiksha';
import Kitty from './components/Zkitty';
import Sriram from './components/Zsriram';
import Prano from './components/Zprano';
import Sriyaa from './components/Zsriyaa';
import FooterC from './components/FooterC';
import { Route, Routes } from "react-router-dom";
import Connectform from './components/Connectform';
import Careerform from './components/Careerform';
import React,{useState} from 'react';
import { useEffect} from 'react';
import loadinggg from './images/loading.gif'

function App() {
  
  const[loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    },8000);
  },[]);
  
  return (
 
      <>
       {
        loading? (
          <div loading={loading} class="bg-black flex flex-col items-center justify-center h-screen">
            <div class="w-full h-[50%] flex items-end justify-center">
              <img src={loadinggg}  class=" text-white" alt=""/>
            </div>
          
            <p class="text-[#909090] text-[15px] w-full text-center sm:text-[15px] al  first-letter pb-20x ">Checking browser capabilities, website may appear slow when viewed in an unsupported browser.</p>
          </div>
        ):(
          <div>
            <div className="fixed z-50 sm:flex hidden ">
          <Navbar />
        </div>
        
        <Routes>
            <Route path='/' exact element={<Home />}  />
            <Route path='/Services' element={<ServicesC />} />
            <Route path='/Mancave/onepe' element={<Onepe />} />
            <Route path='/Mancave/monque' element={<Monque />} />
            <Route path='/Mancave/Jeevan' element={<Jeevan />} />
            <Route path='/Mancave/Wayenedin' element={<Wayenedin />} />
            <Route path='/Mancave' element={<MancaveC />} />
            <Route path='/Mancave/guna' element={<Guna />} />
            <Route path='/Mancave/rishi' element={<Rishi />} />
            <Route path='/Mancave/Kiran' element={<Kiran />} />
            <Route path='/Mancave/pratap' element={<Pratap />} />
            <Route path='/Mancave/thiksha' element={<Thiksha />} />
            <Route path='/Mancave/Kitty' element={<Kitty />} />
            <Route path='/Mancave/sriram' element={<Sriram />} />
            <Route path='/Mancave/Prano' element={<Prano />} />
            <Route path='/Mancave/Sriyaa' element={<Sriyaa />} />
            <Route path='/Connect' element={<ConnectC />} />
            <Route path='/Connect/connectfrom' element={<Connectform />} />
            <Route path='/Career' element={<CareerC />} />
            <Route path='/Career/careerfrom' element={<Careerform />} />
            <Route path='/Footer' element={<FooterC />} />
        </Routes>
          </div>
         )}

        
      </> 
        
    );
}

export default App;