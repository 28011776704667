import weg from '../images/weg.svg'
import wevar1 from '../images/wevar1.svg'
import wevar2 from '../images/wevar2.svg'
import wecp from '../images/wecp.svg'
import webag from '../images/WEBAG.svg'
import webox from '../images/webox.svg'
import webtn from '../images/webtn.svg'
import wetag from '../images/wetag.svg'
import wecat1 from '../images/wecat1.png'
import wecat2 from '../images/wecat2.png'
import wesite from '../images/wesite.svg'
function Wayenedin() {

    document.addEventListener('contextmenu', event => event.preventDefault());
  
    return (
        <div className="">
            {/* Main Page*/}
            <div className='h-screen'>
                <div className="flex flex-row w-full h-[15%]  pt-10  gap-5">
                    <div className='w-10'>

                    </div>
                    <div className=" bg-black w-2 ">
                    </div>

                    <div className="text-3xl text-balck ah w-[70%] h-auto flex  flex-col ">
                        <p className='w-full text-left'>B R A N D </p>
                        <p>R E S O U R C E S</p>
                    </div>
                    
                    <div className=' flex items-center justify-center'>
                        <img src={weg} className="w-[560px] h-[117px]" alt="guna"></img>
                    </div>
                    <div className='w-10'>

                    </div>

                </div>

               <div className='h-[85%] pt-10 flex items-center justify-center gap-10 '>
                    
                    <div className='flex flex-col items-center justify-center gap-5 pl-0'>
                        <p className='text-xl ab text-black '>L O G O / V A R 1</p>
                        <img src={wevar1} className="" alt="guna"></img>
                    </div>
                    <div className='bg-[#BEBEBE] h-[25%] w-[1px]'>

                    </div>
                    <div className='flex flex-col items-center justify-center gap-5'>
                        <p className='text-xl ab text-black'>L O G O / V A R 2</p>
                        <img src={wevar2} className="" alt="guna"></img>
                    </div>
                    <div className='bg-[#BEBEBE] h-[25%] w-[1px]'>

                    </div>
                    <div className='flex flex-col items-center justify-center gap-5 pr-0'>
                        <p className='text-xl ab text-black tracking-widest'>C O L O U R &nbsp; P A L L E T</p>
                        <img src={wecp} className="" alt="guna"></img>
                    </div>
                    

                </div>
                
            </div>

            {/* Package Design */}
            <div className='h-screen'>
                <div className="flex flex-row w-full h-[15%]  pt-10  gap-5">
                    <div className='w-10'>

                    </div>
                    <div className=" bg-black w-2 ">
                    </div>

                    <div className="text-3xl text-balck ah w-[70%] h-auto flex  flex-col ">
                        <p className='w-full text-left'>B R A N D &nbsp; P A C K A G E  </p>
                        <p>D E S I G N</p>
                    </div>


                </div>

                <div className='h-[85%] w-full pt-10 flex items-center justify-center flex-row overflow-clip'>


                        <img src={webag} className="" alt="guna"></img>

                        <img src={webox} className="" alt="guna"></img>


                </div>
                
            </div>
            {/* Package Design */}
            <div className='h-screen'>
                <div className="flex flex-row w-full h-[15%]  pt-10  gap-5">
                    <div className='w-10'>

                    </div>
                    <div className=" bg-black w-2 ">
                    </div>

                    <div className="text-3xl text-balck ah w-[70%] h-auto flex  flex-col ">
                        <p className='w-full text-left'>T R I M </p>
                        <p>D E S I G N</p>
                    </div>
                </div>

                <div className='h-[85%] w-full pt-10 flex items-center justify-center flex-row overflow-clip'>
                        <img src={wetag} className="" alt="guna"></img>
                        <img src={webtn} className="" alt="guna"></img>
                </div>
                
            </div>
            {/* Print Design 1*/}
            <div className=''>
                <img src={wecat1} className="" alt="guna"></img>   
            </div>

            {/* Print Design 2 */}
            <div className=''>
                <img src={wecat2} className="" alt="guna"></img>   
            </div>

            {/* Package Design */}
            <div className='h-screen'>
                <div className="flex flex-row w-full h-[15%]  pt-10  gap-5">
                    <div className='w-10'>

                    </div>
                    <div className=" bg-black w-2 ">
                    </div>

                    <div className="text-3xl text-balck ah w-[70%] h-auto flex  flex-col ">
                        <p className='w-full text-left'>E  C O M M E R C E  </p>
                        <p>S I T E  &nbsp; D E S I G N</p>
                    </div>
                </div>

                <div className='h-[85%] w-full pt-10 flex items-center justify-center flex-row overflow-clip'>
                        <img src={wesite} className="" alt="guna"></img>

                </div>
                
            </div>


        </div>
    )
}

export default Wayenedin