
import mvar1 from '../images/mvar1.svg'
import mvar2 from '../images/mvar2.svg'
import mgrustl from '../images/mgrustl.svg'
import mcp from '../images/mcp.svg'
import mqp1 from '../images/mqp1.svg'
import mqp2 from '../images/mqp2.svg'
import mqip from '../images/mqip.svg'
import mqp from '../images/mqp.svg'


function Monque() {


    document.addEventListener('contextmenu', event => event.preventDefault());
    return(
        <div className="">

            {/* Main Page*/}
            <div className='h-screen'>
                <div className="flex flex-row w-full h-[15%]  pt-10  gap-5">
                    <div className='w-10'>

                    </div>
                    <div className=" bg-black w-2 ">
                    </div>

                    <div className="text-3xl text-balck ah w-[70%] h-auto flex  flex-col ">
                        <p className='w-full text-left'>B R A N D </p>
                        <p>I D E N T I T Y</p>
                    </div>
                    
                    <div className=' flex items-center justify-center'>
                        <img src={mgrustl} className="w-[340px] h-[117px]" alt="guna"></img>
                    </div>
                    <div className='w-10'>

                    </div>

                </div>

                <div className='h-[45%] pt-10 flex items-center justify-center gap-10 '>
                    
                    <div className='flex flex-col items-center justify-center gap-5 pl-0'>
                        <p className='text-xl ab text-black '>L O G O / V A R 1</p>
                        <img src={mvar1} className="" alt="guna"></img>
                    </div>
                    <div className='bg-[#BEBEBE] h-[25%] w-[1px]'>

                    </div>
                    <div className='flex flex-col items-center justify-center gap-5'>
                        <p className='text-xl ab text-black'>L O G O / V A R 2</p>
                        <img src={mvar2} className="" alt="guna"></img>
                    </div>
                    <div className='bg-[#BEBEBE] h-[25%] w-[1px]'>

                    </div>
                    <div className='flex flex-col items-center justify-center gap-5 pr-0'>
                        <p className='text-xl ab text-black tracking-widest'>C O L O U R &nbsp; P A L L E T</p>
                        <img src={mcp} className="" alt="guna"></img>
                    </div>
                    

                </div>
                <div className='h-[2%] flex flex-row'>
                    <div className='h-[1px] w-[40%] bg-white'></div>
                    <div className='h-[1px] w-[15%] bg-[#BEBEBE]'></div>
                    <div className='h-[1px] w-[15%]  bg-white'></div>
                    <div className='h-[1px] w-[15%] bg-[#BEBEBE]'></div>


                </div>

                <div className='h-[38%] w-full flex flex-row  pl-5 pr-5 items-center justify-center gap-5'>
                    <div className='flex w-[65%] flex-col items-center justify-center'>
                        <div className='text-4xl text-center text-[#464646]'>P R O D U C T &nbsp; </div>
                        <div className='text-4xl ab bg-gradient-to-l from-[#171817] to-[#8E8E8E] inline-block  text-transparent bg-clip-text w-full text-center'>D E S I G N </div>

                    </div>

                    <div className='bg-[#BEBEBE] h-[25%] w-[1px] '></div>


                    <div className='w-[35%] h-full flex flex-col items-center justify-center  '>
                        <p className='text-xl ab text-'>F O N T &nbsp; F A C E</p>
                        <div className='text-[53px] ab bg-gradient-to-r from-[#171817] to-[#8E8E8E] inline-block  text-transparent bg-clip-text full'>Avenir</div>
                        <p className='text-[#000000] opacity-50'>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et.Lorem ipsum dolor sit amet lorem ipsum dolor sit amet consectetur adipiscing elit </p>
                    </div>

                </div>

            </div>
            {/* UI Page */}

            <div className='h-screen   flex flex-row pt-10'>
                <div className='w-10'>

                </div>
                <div className=' w-[30%] h-full'>
                    <div className="flex flex-col w-full h-[15%]  pt-10  gap-5">
                        <div className='flex flex-row gap-5'>
                            <div className=" bg-black w-2 ">
                            </div>

                            <div className="text-3xl text-balck ah w-[70%] h-auto flex  flex-col ">
                                <p className='w-full text-left'>U S E R </p>
                                <p>I N T E R F A C E</p>
                            </div>
                        </div>


                    </div>
                    <div className=' h-[85%] flex items-center justify-center'>
                        <p className='ab  text-2xl leading-11'>D A S H B O A R D  / <br></br> A P P &nbsp; I C O N</p>
                    </div>
                </div>
                <div className=' w-[30%] flex justify-center items-start '>
                    <img src={mqp1} className="" alt="guna"></img>
                </div>
                <div className=' w-[30%] flex items-center justify-center'>
                    <img src={mqp2} className="" alt="guna"></img>
                </div>
                <div className='w-[5%]'>

                </div>
            </div>

            {/* User Interface 2 */}
            <div className='h-screen pt-20'>
                <div className="flex flex-row w-full h-[15%]  pt-10  gap-5">
                    <div className='w-10'>

                    </div>
                    <div className=" bg-black w-2 ">
                    </div>

                    <div className="text-3xl text-balck ah w-[70%] h-auto flex  flex-col ">
                        <p className='w-full text-left'>U S E R </p>
                        <p>I N T E R F A C E</p>
                    </div>
                </div>

                <div className='h-full w-full flex flex-row'>
                    <div className='w-[5%]'>

                    </div>
                    <div className='flex w-[60%] items-center justify-center flex-col gap-4'>
                        <img src={mqip} className="h-[85%]" alt="guna"></img>
                        <p className='ab w-[70%] flex items-start justify-end text-2xl leading-11'>D A S H B O A R D  / <br></br>  I P A D</p>
                    </div>
                    <div className='flex w-[30%] items-center justify-center flex-col '>
                        <img src={mqp} className="h-[85%] scale-75" alt="guna"></img>
                        <p className='ab w-[70%] flex items-start justify-end text-2xl leading-11'>M O N  Q U E  / <br></br>   P L A Y E R</p>

                    </div>
                    <div className='w-[5%]'>

                    </div>

                </div>
            </div>
        </div>
 
    )
}

export default Monque