import jeevanui from '../images/jeevanui.svg'
import jeevanweb from '../images/jeevanweb.svg'
import jeevang from '../images/jeevang.svg'

function Jeevan() {


    document.addEventListener('contextmenu', event => event.preventDefault());
    return(
        <div className="">
            {/* Case Study */}
            <div className=''>
            <div className="flex flex-row w-full h-[15%]  pt-10  gap-5">
                    <div className='w-10'>

                    </div>
                    <div className=" bg-black w-2 ">
                    </div>

                    <div className="text-3xl text-balck ah w-[70%] h-auto flex  flex-col ">
                        <p className='w-full text-left'>C A S E </p>
                        <p>S T U D Y</p>
                    </div>
                    
                    <div className=' flex items-center justify-center'>
                        <img src={jeevang} className="w-[608px] h-[117px]" alt="guna"></img>
                    </div>
                    <div className='w-10'>

                    </div>

                </div>

                

                <div className='h-[85%] flex flex-row'>
                    <div className='w-[5%]'></div>

                    <div className='w-[65%] h-[50%] flex flex-col pt-20'>
                        <div className='text-[26px] text-black ab'>
                            P R O D U C T &nbsp; : &nbsp; O V E R V I E W
                        </div>
                        <div className='text-[19px] text-black al w-[80%] pt-5'>
                            OnePe is a financial technology (FinTech) app that is devoted to providing digital 
                            solutions for reducing the use of paper bills and streamlining the billing and payment process. 
                            Its goal is to make the process more efficient and environmentally friendly.
                        </div>

                        <div className='text-[26px] text-black ab pt-10'>
                            A I M
                        </div>
                        <div className='text-[19px] text-black al w-[80%] pt-5'>
                            Our objective for this fin-tech startup was to implement transformative changes that would 
                            enable it to compete with established players in the market and gain a head start in the race. 
                            To achieve this, we planned to redesign the user interface from scratch to give the app a personalised 
                            look. For the branding aspect, we wanted to convey a sleek, minimalistic aesthetic while also highlighting 
                            the company's focus on technology and environmental sustainability, which is why we chose blue and green 
                            accents. Our goal was to create a strong, distinctive brand identity for the startup.
                        </div>

                        <div className='text-[26px] text-black ab pt-10'>
                            S T R A TE G I E S &nbsp; : &nbsp; B U I L D
                        </div>
                        <div className='text-[19px] text-black al w-[80%] pt-5'>
                            <p className='ah text-[19px]'>Brand Revamp: A Complete Overhaul:<br></br> </p>
                            <div className='al text-[19px]'>
                                &nbsp;&nbsp;&nbsp;&nbsp;•Selecting the ideal theme.<br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;•Analysing the perfect font theme.<br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;•Building out of the box banners for play store and App store.<br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;•Motion graphic elements that results in attention to details. <br></br>
                            </div>

                            <p className='ah pt-5'>Innovative Design: Crafting the Ultimate User Interface: <br></br> </p>
                            <div className='al'>
                                &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;Design an interface that makes the app give the best visual as well as user experience. <br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;Scrap existing icons and craft them from scratch to compliment the new interface. <br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;Introduce new concepts like OnePoints wallet in the place of generic rewards rewards and bill history <br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;envelope for a better detail elevation.<br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;Integrate creative API's like third party maps for a unique stand.  <br></br>
                            </div>

                            <p className='ah pt-5'> Supportive Solutions: Your Guide to Expert Help Documentation : <br></br> </p>
                            <div className='al'>
                                &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;Make High quality explainer videos to make it easier for pitching the product to vendors <br></br>
                            </div>
                        </div>
                    </div>

                    <div className='w-[5%]'></div>
                </div>
            </div>

            {/* Systematic Analysis */}
            <div className='h-screen  pt-20'>
                <div className="flex flex-row w-full h-[15%]  pt-10  gap-5">
                    <div className='w-10'>

                    </div>

                    <div className=" bg-black w-2 "></div>

                    <div className="text-3xl text-balck ah w-[70%] h-auto flex  flex-col ">
                        <p className='w-full text-left'>S Y S T E M A T I C &nbsp; A N A L Y S I S :  </p>
                        <p>P R E &nbsp; L O O K &nbsp; (I N T E R F A C E)</p>
                    </div>
                </div>

                <div className='flex flex-row h-[85%] items-center justify-center'>
                    <div className='w-[50%] flex items-center justify-center pt-10'>
                        <img src={jeevanui} className="" alt="guna"></img>
                    </div>

                    <div className='w-[49%] flex flex-col gap-10 items-center justify-center'>
                        <div className='text-2xl text-black ah'>
                             P R E V I O S L Y &nbsp; E X I S T E D &nbsp; I N T E R F A C E
                        </div>
                        <div className='text-[19px] text-black al w-[80%] text-justify'>
                            Upon reviewing Onepe's interface, we noticed that it lacks the standout 
                            features and design elements that can set it apart from it's competitors. 
                            While the interface is functional, it lacks the visually appealing elements 
                            and advanced features that enhance the user experience. We also noticed a few 
                            flaws that negatively impact the user experience, such as the integration of  
                            Generic Maps on the explore screen and the use of a generic bill template on the 
                            bill screen. All these factors put together immediately made the app a little too 
                            basic. That's when we decided to act.
                        </div>

                    </div>

                </div>

            </div>


             {/* New Website */}
             <div className='h-screen  pt-20'>
                <div className="flex flex-row w-full h-[15%]  pt-10  gap-5">
                    <div className='w-10'>

                    </div>

                    <div className=" bg-black w-2 "></div>

                    <div className="text-3xl text-balck ah w-[70%] h-auto flex  flex-col ">
                        <p className='w-full text-left'>R E B R A N  D E D</p>
                        <p>W E B S I T E</p>
                    </div>
                </div>

                <div className='flex flex-row h-[85%] items-center justify-center'>
                    <img src={jeevanweb} className="" alt="guna"></img>
                </div>

            </div>




        </div>
 
    )
}

export default Jeevan